<template>
  <div id="app" class="d-flex flex-column dvh-100" :style="colorStore.cssVars">
    <NavBar />
    <BaseAlert></BaseAlert>
    <BodyLayout class="flex-fill overflow-auto" />
    <NavFooter />
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch } from 'vue';
import { setTimezone } from './shared/formatters';
import { useBotStore } from './stores/ftbotwrapper';
import { useUserService } from './shared/userService';
import { useSettingsStore } from './stores/settings';
import { useColorStore } from './stores/colors';
import { AuthPayload } from '@/types';

const settingsStore = useSettingsStore();
const colorStore = useColorStore();
const botStore = useBotStore();

onMounted(async () => {
  setTimezone(settingsStore.timezone);
  colorStore.updateProfitLossColor();

  localStorage.removeItem('ftAuthLoginInfo');
  localStorage.removeItem('ftSelectedBot');

  const addBot = async (botName: string, apiUrl: string, username: string, password: string) => {
    const auth: AuthPayload = {
      botName,
      url: apiUrl,
      username,
      password,
    };

    const existingBot = Object.values(botStore.availableBots).find(
      (bot) => bot.botName === botName && bot.botUrl === apiUrl
    );

    if (!existingBot) {
      try {
        const newBotId = botStore.nextBotId;
        const userService = useUserService(newBotId);
        await userService.login(auth);

        botStore.addBot({
          botName,
          botId: newBotId,
          botUrl: apiUrl,
          sortId: Object.keys(botStore.availableBots).length + 1,
        });

        // Set bot's login and online state
        botStore.botStores[newBotId].isBotLoggedIn = true;
        botStore.botStores[newBotId].isBotOnline = true;

        const refreshTokenInterval = async () => {
          try {
            await userService.refreshToken();
            console.log('Token refreshed successfully for bot:', botName);
          } catch (error) {
            console.error('Failed to refresh token for bot:', botName, error);
          }
        };

        // Set initial token refresh interval
        setInterval(refreshTokenInterval, 5 * 60 * 1000);

        botStore.selectBot(newBotId);
        botStore.allRefreshFull();
        console.log('Bot added successfully:', botName);
      } catch (error) {
        console.error('Failed to add bot:', botName, error);
      }
    } else {
      console.log('Bot already exists:', botName);
    }
  };

  try {
    await addBot('The Chessmaster', 'https://trade.imtcoin.com', '', '');
    await addBot('Auric Goldfinger', 'https://trade2.imtcoin.com', '', '');
    await addBot('The Terminator', 'https://trade3.imtcoin.com', '', '');
    await addBot('Agent Smith', 'https://trade4.imtcoin.com', '', '');
    await addBot('Jabba The Hut', 'https://trade5.imtcoin.com', '', '');
    await addBot('Gordon Gekko', 'https://trade6.imtcoin.com', '', '');
    await addBot('The Samurai', 'https://trade7.imtcoin.com', '', '');
    await addBot('A.I. Warren Buffett', 'https://trade8.imtcoin.com', '', '');
    await addBot('A.I. Ray Dalio', 'https://trade9.imtcoin.com', '', '');
    await addBot('A.I. Jim Simons', 'https://trade10.imtcoin.com', '', '');
    await addBot('Louis Winthorpe III', 'https://trade11.imtcoin.com', '', '');
    await addBot('Lex Luthor', 'https://trade12.imtcoin.com', '', '');
    await addBot('Count Dracula', 'https://trade13.imtcoin.com', '', '');
    await addBot('A.I. Martha Stewart', 'https://trade14.imtcoin.com', '', '');
  } catch (error) {
    console.error('Failed to initialize bots:', error);
  }
});

watch(
  () => settingsStore.timezone,
  (tz) => {
    console.log('Timezone changed:', tz);
    setTimezone(tz);
  },
);
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>

